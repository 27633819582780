import React, {Component} from 'react';
export default class News extends Component {
    render(){
        return (
            <div className='border-top relative t-14'>
                <div className="marquee">
                    <ul className="marquee-content">
                        <li className="marquee-item"><span className="date">&nbsp;[Nov.2021]</span> Invited Talk at GyeongGi Cultural Foundation in Korea</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Dec.2021]</span> Invited Talk / Workshop at San Jose State University</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Jan.2022]</span> Juror, PacificVis 2022</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Mar.2022]</span> Invited Talk at Ohio State University</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Jan.2023]</span> Juror, PacificVis 2023</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Mar.2023]</span> Program Committee, IEEEVis Art Programs</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Apr.2023]</span> Peer Reviewer, IEEEVis 2023</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Apr.2023]</span> Accepted for Exhibition (Solar System), IEEEVis Art Programs 2023</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Apr.2023]</span> Awarded Diversity and Inclusion Scholarship, IEEEVis 2023</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Mar.2024]</span> Peer Reviewer, DIS 2024</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Apr.2024]</span> Program Committee, IEEEVis Art Programs</li>
                        <li className="marquee-item"><span className="date">&nbsp;[May.2024]</span> Peer Reviewer, IEEEVIS 2024</li>
                        <li className="marquee-item"><span className="date">&nbsp;[May.2024]</span> DIS24: Recognized for contributions (paper reviewer) with a complimentary one-year ACM Professional Membership</li>
                        <li className="marquee-item"><span className="date">&nbsp;[July.2024]</span> Earned a CS Master's degree</li>
                        <li className="marquee-item"><span className="date">&nbsp;[Jan.2025]</span> Fellow, UMD Future Faculty Program</li>
                    </ul>
                </div>
            </div>
        )
    }
}